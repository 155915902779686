<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="cash_invoices"
                          columnsPrefix="cash_invoice.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.menu.account-book.bar-kasse"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline class="mb-2">
                            <font-awesome-icon
                                role="button"
                                icon="chevron-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="chevron-right"
                                @click="nextMonth()"
                            />

                            <b-form-select
                                v-model="month"
                                :options="yearsList()"
                                class="ml-3"
                                @change="yearChanged"
                            ></b-form-select>
                        </b-form>
                    </template>

                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="openCashInvoice(row.item)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'CashInvoices',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            month: null,
            dateStart: null,
            dateEnd: null,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('CashInvoices', ['getCashInvoicesTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CashInvoices/fetchCashInvoices', 'getCashInvoicesTable');
        },
        openCashInvoice(invoice) {
            this.$root.$children[0].openModal('cash-invoice-modal', {
                    id: invoice ? invoice.id : null,
                }, this.refreshTable,
                {}
            )
        },
        getActions() {
            return [
                {
                    title: this.$t('cash_invoice.button.create_cash_invoice'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('cash', 'create'),
                    click: () => {

                        this.openCashInvoice({})
                    }
                },
            ]
        }
    },
}
</script>